<template>
  <div :is="layout">
    <v-container fluid>
      <v-row>
        <v-col cols="3">
          <v-text-field @keypress.enter="doGet(1)" v-model="search_info.number"
                        :label="$t('airport_security.number')"></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-btn @click="doGet(1)" large color="secondary2">{{$t('search')}}</v-btn>
        </v-col>
      </v-row>
      <!-- <v-data-table
          :server-items-length="list_count"
          :headers="headers"
          :items="list"
          :items-per-page="10"
          :loading-text="$t('data_loading')"
          :loading="table_loading"
          @pagination="changePagination"
          class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar
              flat
          >
            <v-toolbar-title>{{$t('airport_security.title')}}</v-toolbar-title>
            <v-divider
                class="mx-4"
                inset
                vertical
            ></v-divider>
          </v-toolbar>
        </template>
        <template v-slot:item.operation="{item}">
          <v-btn @click="edit(item)" small color="primary" text
          >{{$t('edit')}}</v-btn
          >
          <v-btn @click="del(item.id)" small color="primary" text> {{$t('delect')}}</v-btn>
        </template>
      </v-data-table> -->

                   <table-list :list="list" :listCount="list_count" @change="changePagination">  
            <template #icon>
                <i class="airport icon-baozhangche- icon_font"></i>
            </template>
            <template  #item="{item}">
            <div style="margin-bottom:10px">{{item.number}}</div>
            <div class="small_font">{{$t('airport_security.garage_number')}}: {{item.garage_number}}</div>
            <div class="small_font">{{$t('airport_security.refueling_speed')}}: {{item.refueling_speed}}</div>
            <div class="small_font">{{$t('airport_security.type')}}: {{item.type}}</div>
            </template>
            <template #active="{item}">
                <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs"
                        color="secondary"
                        outlined
                        text
                        @click="edit(item)"
                        v-on="on"  x-small fab>
                        <i class="airport small-font icon-bianji "
                        style='font-size: 18px !important; color: #b5bac1'></i>
                    </v-btn>
                </template>
                <span>{{$t('edit')}}</span>
            </v-tooltip>
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs"
                        color="secondary"
                        text
                        v-on="on"
                        outlined
                        @click="del(item.id)"
                        class="ml-2" depressed x-small fab>
                        <i class="airport small-font icon-shanchu"
                        style='font-size: 18px !important; color: #b5bac1'></i>
                    </v-btn>
                </template>
                <span>{{$t('delect')}}</span>
            </v-tooltip>
            </template>
        </table-list>
    </v-container>
    <v-btn @click="add_open" color="primary" fab class="float-btn">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" width="800px">
      <v-card color="secondary">
        <v-card-title>{{$t(edit_mode ? 'airport_security.edit_vehicle_info' : 'airport_security.add_vehicle_info')}}</v-card-title>
        <v-container class="pd-30">
          <v-row>
            <v-col cols="8">
              <v-text-field v-model="info.number" :label="$t('airport_security.number')"></v-text-field>
            </v-col>
            <v-col cols="8">
              <v-text-field v-model="info.garage_number"
                            :label="$t('airport_security.garage_number')"></v-text-field>
            </v-col>
            <v-col cols="8">
              <v-text-field type="number" v-model="info.refueling_speed"
                            :label="$t('airport_security.refueling_speed')"></v-text-field>
            </v-col>
            <v-col cols="8">
              <v-select
                  :items="typeList"
                  item-value="id"
                  item-text="name"
                  v-model="info.type"
                  :label="$t('airport_security.type')"
                  required
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="submit" color="accent">{{$t('confirm')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import * as vehicle from "@/api/airport_security";
import TableList from '@/components/TableList'
export default {
  name: 'vehicleInfoList',
  components:{TableList},
  data: () => ({
    list: [],
    layout: null,
    edit_mode: false,
    search_info: {
      pageindex: 1,
      pagesize: 10,
      number: ''
    },
    table_loading: false,
    dialog: false,
    list_count: 0,
    info: {
      number:'',
      garage_number:'',
      refueling_speed:'',
      type:''
    },
    typeList:[],
    typeValue:{},
    add_loading: false
  }),
  methods: {
    doGet(pageindex = this.search_info.pageindex) {
      this.search_info.pageindex = pageindex
      this.table_loading = true
      const closeLoading = this.$loading()
      vehicle.getInfoList(this.search_info).then(res => {
        this.list = res.results
        this.list_count = Math.ceil(res.count/this.search_info.pagesize)
      }).finally(() => {
        this.table_loading = false
        closeLoading()
      })
    },
    changePagination(data) {
      this.search_info.pageindex = data.page
      // this.search_info.pagesize = data.itemsPerPage
      this.doGet()
    },
    edit(data){
      this.edit_mode = true
      for (let i in this.info) {
        this.info[i] = data[i]
      }
      this.info.id = data.id
      this.dialog = true
    },
    del(id){
      this.$confirm({
          title: this.$t('confirm_del'),
          content:  this.$t('airport_security.delete_air_sec'),
          okText: this.$t('confirm'),
          cancelText: this.$t('cancel')
      })
          .then((dialog) => {
            vehicle.deleteInfoList(id).then(res => {
              this.doGet()
            })
          })
    },
    add_open(){
      this.edit_mode = false
      for (let i in this.info) {
        // reset value
        this.info[i] = ''
      }
      if ('id' in this.info) {
        delete this.info.id
      }
      this.dialog = true
    },
    submit(){
      this.add_loading = true
      if (this.edit_mode) {
        vehicle.editInfoList(this.info).then(res => {
          this.$toast({
            title: this.$t('success'),
            message:this.$t('airport_security.edit_success_air_sec'),
          })
          this.dialog = false
        }).finally(() => {
          this.add_loading = false
          this.doGet()
        })
      } else {
        vehicle.postInfoList(this.info).then(res => {
          this.$toast({
            title: this.$t('success'),
            message: this.$t('airport_security.add_success_air_sec'),
          })
          this.dialog = false
        }).finally(() => {
          this.add_loading = false
          this.doGet()
        })
      }
    }
  },
  mounted() {
    this.layout = this.$route.meta.use_layout
    this.doGet()
    vehicle.getTypeList({pageindex: 1, pagesize: 9999}).then(res=>{
      this.typeList=res.results
      this.typeValue=this.typeList.reduce((acc,cur)=>{
        acc[cur.id]=cur.name
        return acc
      },{})
    })
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('airport_security.number'),
          align: 'center',
          value: 'number',
          sortable: false
        },
        {
          text: this.$t('airport_security.garage_number'),
          align: 'center',
          value: 'garage_number',
          sortable: false
        },
        {
          text: this.$t('airport_security.refueling_speed'),
          align: 'center',
          value: 'refueling_speed',
          sortable: false
        },
        {
          text: this.$t('airport_security.type'),
          align: 'center',
          value: 'type',
          sortable: false
        },
        {
          text: this.$t('airport_security.create_time'),
          align: 'center',
          value: 'create_time',
          sortable: false
        },
        {
          text: this.$t('airport_security.update_time'),
          align: 'center',
          value: 'update_time',
          sortable: false
        },
        {
          text: this.$t('airport_security.operation'),
          align: 'center',
          value: 'operation',
          sortable: false
        },
      ]
    }
  }

}
</script>
<style lang="stylus" scoped>
   @import '~@/assets/css/global.styl'
    .small_font
        font-size 14px !important
        padding 2px 0px
    .icon_font
        font-size 120px !important
</style>
